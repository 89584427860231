import { Injectable } from '@angular/core';
import * as Ably from 'ably';
import { HttpClient } from '@angular/common/http';
import { endpoints } from '../common/enpoints';
import { Observable, Subject } from 'rxjs';
import { albyChannelKey } from '../../../../config';
import { Types } from 'ably';
import Message = Types.Message;
import { take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { AreaPauseStatus } from '../interfaces/area';
import { AdminLocalityService } from '../../admin/services/admin-locality.service';

export enum REAL_TIME_MESSAGE_TYPE {
  DELIVERER_STATISTIC_UPDATED = 'DELIVERER-STATISTIC-UPDATED',
  CHAT_MESSAGE = 'CHAT-MESSAGE',
  ORDER_UPDATED = 'ORDER-UPDATED',
  ORDER_CREATED = 'ORDER-CREATED',
  INCIDENT_CREATED = 'INCIDENT-CREATED',
  AREA_PAUSING = 'AREA-PAUSING'
}

@Injectable({
  providedIn: 'root'
})
export class RealtimeService {
  private areaPauseStatus: Subject<AreaPauseStatus> = new Subject();
  private chatMessage = new Subject();
  private orderChangeMessage = new Subject();
  private deliveryStatisticMessage = new Subject();
  public connection: Types.RealtimeCallbacks;

  constructor(private http: HttpClient,
              private toastrService: ToastrService,
              private translate: TranslateService,
              private localityService: AdminLocalityService,
              private router: Router) {
  }

  /**
   * init ably realtime connection
   */
  public initRealtimeConnection() {
    if (!this.connection) {
      this.http.get(endpoints.broadcasting.auth).subscribe((res: any) => {
        this.initConnection(res.ablyToken);
      });
    }
  }

  public initConnection(ablyToken: string) {
    console.log('[INIT ABLY CONNECTION]');
    const options: Ably.Types.ClientOptions = {
      token: ablyToken,
      authCallback: (data, callback) => this.getToken(callback)
    };
    if (!this.connection) {
      this.connection = new Ably.Realtime(options);
      this.connection.channels.get(albyChannelKey).subscribe(
        (singleRes: Message) => {
          console.log('[ABLY RECEIVED DEFAULT CHANNEL]');
          this.divideNotificationByType(singleRes);
          this.handleNotificationToast(singleRes);
        }
      );
      this.connection.channels.get('private:' + this.localityService.getCurrentLocalityValue().channel).subscribe(
        (singleRes: Message) => {
          console.log('[ABLY RECEIVED CURRENT LOCALITY]');
          this.divideNotificationByType(singleRes);
          this.handleNotificationToast(singleRes);
        }
      );
      this.connection.channels.get('disconnect').subscribe(() => {
        this.connection = null;
        this.initConnection(ablyToken);
      });
    }
  }

  public disconnect() {
    if (this.connection) {
      console.log('[DESTROY ABLY CONNECTION]');
      this.connection.connection.off();
      this.connection.close();
    }
  }

  private getToken(callback) {
    this.http.get(endpoints.broadcasting.auth).subscribe((res: any) => {
      callback(null, res.ablyToken);
    }, e => {
    });
  }


  public getChatMessage(): Observable<any> {
    return this.chatMessage.asObservable();
  }

  public getAreasPauseStatus(): Observable<any> {
    return this.areaPauseStatus.asObservable();
  }

  public getOrderChangedMessage(): Observable<any> {
    return this.orderChangeMessage.asObservable();
  }

  public getDeliveryStatisticMessage(): Observable<any> {
    return this.deliveryStatisticMessage.asObservable();
  }


  private divideNotificationByType(message) {
    console.log('aa');
    console.log(message);
    const notificationData = JSON.parse(message.data);
    console.log(notificationData);
    switch (message.name) {
      case REAL_TIME_MESSAGE_TYPE.CHAT_MESSAGE:
        this.chatMessage.next(message);
        break;
      case REAL_TIME_MESSAGE_TYPE.DELIVERER_STATISTIC_UPDATED:
        this.deliveryStatisticMessage.next(message);
        break;
      case REAL_TIME_MESSAGE_TYPE.ORDER_UPDATED:
      case REAL_TIME_MESSAGE_TYPE.ORDER_CREATED:
        this.orderChangeMessage.next(message);
        break;
      case REAL_TIME_MESSAGE_TYPE.AREA_PAUSING:
        this.areaPauseStatus.next(notificationData.data);
        break;
    }
  }

  /**
   * handle notification depend on message type
   * @param message
   * @private
   */
  private handleNotificationToast(message) {
    console.log(message);
    const data = JSON.parse(message.data);
    console.log('NOTIFICATIONDATA');
    console.log(data);
    const toastConfig = this.createToastConfig(message.name);
    const toastTitle = this.getToastTitle(message.name);
    const toastMessage = message.name === REAL_TIME_MESSAGE_TYPE.CHAT_MESSAGE ? `${data.data.sender.email}` : '';
    const toastSub = this.toastrService.show(
      toastMessage,
      toastTitle,
      toastConfig).onTap.pipe(take(1)).subscribe(
      () => {
        switch (message.name) {
          case  REAL_TIME_MESSAGE_TYPE.CHAT_MESSAGE:
            this.router.navigate(['/', 'admin', 'chat', data.data.roomId]);
            break;
          case REAL_TIME_MESSAGE_TYPE.ORDER_CREATED:
          case REAL_TIME_MESSAGE_TYPE.ORDER_UPDATED:
            this.router.navigate(['/', 'admin', 'orders', 'edit', data.data.orderId]);
            break;
          case REAL_TIME_MESSAGE_TYPE.DELIVERER_STATISTIC_UPDATED:
            this.router.navigate(['/', 'admin', 'users', 'deliveries']);
            break;
          case REAL_TIME_MESSAGE_TYPE.AREA_PAUSING:
            this.router.navigate(['/', 'admin', 'lookups', 'delivery-zones', 'pause']);
            break;
          // /admin/orders/incidents?incidentId=c7ba4581-43df-42eb-9cc7-ad8f6154a65e
          case REAL_TIME_MESSAGE_TYPE.INCIDENT_CREATED:
            this.router.navigate(['/', 'admin', 'orders', 'incidents'], {
              queryParams: {
                incidentId: data.data.incidentId
              }
            });
            break;
        }
        toastSub.unsubscribe();
      }
    );
  }

  /**
   * create config for toast
   * depend on notification type
   * switching between some options
   *
   * @param messageType
   * @private
   */
  private createToastConfig(messageType: REAL_TIME_MESSAGE_TYPE): IndividualConfig | any {
    const toastColorClass = messageType !== REAL_TIME_MESSAGE_TYPE.CHAT_MESSAGE ? (messageType === REAL_TIME_MESSAGE_TYPE.ORDER_UPDATED || messageType === REAL_TIME_MESSAGE_TYPE.ORDER_CREATED ? 'toast-order' : 'toast-basic') : '';
    const disableTimeOut = messageType === REAL_TIME_MESSAGE_TYPE.CHAT_MESSAGE;
    return {
      tapToDismiss: true,
      disableTimeOut,
      progressBar: !disableTimeOut,
      timeOut: !disableTimeOut ? 10000 : 0,
      toastClass: `ngx-toastr toast-info ${toastColorClass}`,
      closeButton: true,
      newestOnTop: true,
    };
  }


  /**
   * Create title to toast depend od message type
   *
   * @param messageType: type of notification
   * @private
   */
  private getToastTitle(messageType: REAL_TIME_MESSAGE_TYPE): string {
    console.log(messageType);
    switch (messageType) {
      case REAL_TIME_MESSAGE_TYPE.CHAT_MESSAGE:
        return this.translate.instant('AdminNotification.title.newMessage');
      case REAL_TIME_MESSAGE_TYPE.ORDER_CREATED:
        return this.translate.instant('AdminNotification.title.newOrderCreated');
      case REAL_TIME_MESSAGE_TYPE.ORDER_UPDATED:
        return this.translate.instant('AdminNotification.title.orderUpdated');
      case REAL_TIME_MESSAGE_TYPE.DELIVERER_STATISTIC_UPDATED:
        return this.translate.instant('AdminNotification.title.deliveryStatisticUpdated');
      case REAL_TIME_MESSAGE_TYPE.INCIDENT_CREATED:
        return this.translate.instant('AdminNotification.title.incidentCreated');
      case REAL_TIME_MESSAGE_TYPE.AREA_PAUSING:
        return this.translate.instant('AdminNotification.title.areaPauseStateChanged');
      default :
        return this.translate.instant('AdminNotification.title.newNotification');

    }
  }

}
